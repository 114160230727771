import React, { lazy } from 'react';
import { pages } from '../../menu';

export const PREFIX_PRODUCT = 'product/*';
export const PREFIX_DEPARTAMET = 'departament/*';
export const PREFIX_SERVICE = 'service/*';
export const PREFIX_CUSTOMER = 'customer/*';
export const PREFIX_SCHENDULE = 'schedule/*';
export const PREFIX_EMPLOYEE = 'employee/*';
export const PREFIX_PROVIDER = 'provider/*';
export const PREFIX_COMMAND = 'command/*';
export const PREFIX_SIGNATURE = 'signature/*';

const FUNCTIONAL = {
    PRODUCT: {
        EDIT: lazy(() => import('../../pages/Functional/Product/Edit')),
        LIST: lazy(() => import('../../pages/Functional/Product/ListProduct'))
    },
    SERVICE: {
        LIST: lazy(() => import('../../pages/Functional/Product/ListService')),
        EDIT: lazy(() => import('../../pages/Functional/Product/Edit')),
    },
    DEPARTAMENT: {
        LIST: lazy(() => import('../../pages/Functional/Departament/List'))
    },
    EMPLOYEE: {
        DASHBOARD: lazy(() => import('../../pages/Functional/Employee/Employee')),
        BARBER: {
            EDIT: lazy(() => import('../../pages/Functional/Employee/Barber/Edit')),
        },
        SALESMAN: {
            EDIT: lazy(() => import('../../pages/Functional/Employee/Salesman/Edit')),
        },
    },
    CUSTOMER: {
        LIST: lazy(() => import('../../pages/Functional/Customer/List'))
    },
    PROVIDER: {
        LIST: lazy(() => import('../../pages/Functional/Provider/List'))
    },
    COMMAND: {
        LIST: lazy(() => import('../../pages/Functional/Command/List'))
    },
    SCHENDULE: { 
        DASHBOARD: lazy(() => import('../../pages/Functional/Schendule/DashboardBooking'))
    },
    SIGNATURE: {
        LIST: lazy(() => import('../../pages/Functional/Signature/List')),
        EDIT: lazy(() => import('../../pages/Functional/Signature/Edit')),
        MANAGEMENT: {
            LIST: lazy(() => import('../../pages/Functional/Signature/Management/List')),
            EDIT: lazy(() => import('../../pages/Functional/Signature/Management/Edit')),
        }
    },
};

const presentation = [
    {
        path: pages.departament.list.path,
        element: <FUNCTIONAL.DEPARTAMENT.LIST />,
        exact: true
    },
    {
        path: pages.product.listService.path,
        element: <FUNCTIONAL.SERVICE.LIST />,
        exact: true,
        role: 'ROLE_MANAGER'
    },
    {
        path: pages.product.editServiceID.path,
        element: <FUNCTIONAL.PRODUCT.EDIT />,
        exact: true,
        role: 'ROLE_MANAGER'
    },
    {
        path: `${pages.product.editServiceID.path}/:id`,
        element: <FUNCTIONAL.PRODUCT.EDIT />,
        exact: true,
        role: 'ROLE_MANAGER'
    },
    {
        path: pages.product.list.path,
        element: <FUNCTIONAL.PRODUCT.LIST />,
        exact: true
    },
    {
        path: pages.product.editID.path,
        element: <FUNCTIONAL.PRODUCT.EDIT />,
        exact: true
    },
    {
        path: `${pages.product.editID.path}/:id`,
        element: <FUNCTIONAL.PRODUCT.EDIT />,
        exact: true
    },
    {
        path: pages.employee.dashboard.path,
        element: <FUNCTIONAL.EMPLOYEE.DASHBOARD />,
        exact: true,
        role: 'ROLE_MANAGER'
    },
    {
        path: pages.employee.barber.editBarberId.path,
        element: <FUNCTIONAL.EMPLOYEE.BARBER.EDIT />,
        exact: true,
        role: 'ROLE_MANAGER'
    },
    {
        path: `${pages.employee.barber.editBarberId.path}/:id`,
        element: <FUNCTIONAL.EMPLOYEE.BARBER.EDIT />,
        exact: true,
        role: 'ROLE_MANAGER'
    },
    {
        path: pages.employee.salesman.edit.path,
        element: <FUNCTIONAL.EMPLOYEE.SALESMAN.EDIT />,
        exact: true,
        role: 'ROLE_MANAGER'
    },
    {
        path: `${pages.employee.salesman.editId.path}/:id`,
        element: <FUNCTIONAL.EMPLOYEE.SALESMAN.EDIT />,
        exact: true,
        role: 'ROLE_MANAGER'
    },
    {
        path: pages.customer.list.path,
        element: <FUNCTIONAL.CUSTOMER.LIST />,
        exact: true
    },
    {
        path: pages.schendule.dashboardBooking.path,
        element: <FUNCTIONAL.SCHENDULE.DASHBOARD />,
        exact: true
    },
    {
        path: pages.provider.list.path,
        element: <FUNCTIONAL.PROVIDER.LIST />,
        exact: true
    },
    {
        path: pages.command.dashboardCommand.path,
        element: <FUNCTIONAL.COMMAND.LIST />,
        exact: true
    },
    {
        path: pages.signature.list.path,
        element: <FUNCTIONAL.SIGNATURE.LIST />,
        exact: true,
        role: 'ROLE_MANAGER'
    },
    {
        path: pages.signature.editID.path,
        element: <FUNCTIONAL.SIGNATURE.EDIT />,
        exact: true,
        role: 'ROLE_MANAGER'
    },
    {
        path: `${pages.signature.editID.path}/:id`,
        element: <FUNCTIONAL.SIGNATURE.EDIT />,
        exact: true,
        role: 'ROLE_MANAGER'
    },
    {
        path: pages.signature.managementList.path,
        element: <FUNCTIONAL.SIGNATURE.MANAGEMENT.LIST />,
        exact: true
    },
    {
        path: pages.signature.managementID.path,
        element: <FUNCTIONAL.SIGNATURE.MANAGEMENT.EDIT />,
        exact: true
    },
    {
        path: `${pages.signature.managementID.path}/:id`,
        element: <FUNCTIONAL.SIGNATURE.MANAGEMENT.EDIT />,
        exact: true
    },
]

export default presentation;