import {useEffect, useState} from 'react';
import ReactCalendar from 'react-calendar';
import { format } from 'date-fns';
import 'react-calendar/dist/Calendar.css';
import api from "../../../../services/api";
import showNotification from "../../../../components/extras/showNotification";

export function Calendar({ handleContinue, booking, companySelected }) {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);

  const getHours = (date) => {
    api.post(`externalSchedule/getSchendule`, {
      'services': booking.services,
      'timeEstimated': booking.timeEstimated,
      'valueTotal': booking.valueTotal,
      'barberId': booking.barber !== null ? booking.barber.id : null,
      'date': date,
      'companyId': companySelected.id
    }).then(resp => {
      if (resp.data.error) {
        showNotification('Atenção!', resp.data.message, 'warning', true);
        setAvailableTimeSlots([]);
        return false;
      }

      if (resp.data.hours.length <= 0) {
        showNotification('Atenção!', 'Nenhum horário encontrado.', 'warning', true);
        setAvailableTimeSlots([]);
      } else {
        showNotification('Sucesso!', 'Encontramos alguns horário, escolha o melhor para você.', 'success', true);
        setAvailableTimeSlots(resp.data.hours[0].hours);
      }
    });
  }

  const handleDateChange = (newDate) => {console.log(newDate)
    setSelectedDate(newDate);
    setSelectedTimeSlot(null);
    const formattedDate = format(newDate, 'yyyy-MM-dd');
    getHours(formattedDate)
  };

  const handleTimeSlotSelect = (timeSlot) => {
    setSelectedTimeSlot(timeSlot);
  };

  useEffect(() => {
    const formattedDate = format(new Date(), 'yyyy-MM-dd');
    getHours(formattedDate)
  }, [booking.timeEstimated]);

  return (
    <div className="calendar-time-slots-wrapper">
      <div className="calendar-content">
        <div className="calendar-title">
          <h2>Escolha a Data</h2>
        </div>
        <ReactCalendar
          onChange={handleDateChange}
          value={selectedDate}
          locale="pt-BR"
          minDate={new Date()}
          className="custom-calendar"
          tileDisabled={({ date }) => date.getDay() === 0}
        />
        <div className="time-slots-container">
          <h3>Horários Disponíveis</h3>
          {availableTimeSlots.length > 0 ? (
            <div className="time-slots-scroll">
              <ul>
                {availableTimeSlots.map((timeSlot, index) => (
                  <>
                    <li
                        key={index}
                        className={timeSlot === selectedTimeSlot ? 'selected' : ''}
                        onClick={() => handleTimeSlotSelect(timeSlot)}
                    >
                      {timeSlot}
                    </li>
                  </>
                ))}
              </ul>
            </div>
          ) : (
            <p>Sem horários disponíveis para esta data.</p>
          )}
          <div className="calendar-actions">
            <button
              onClick={() => handleContinue({
                selectedTimeSlot,
                selectedDate
              })}
              className="continue-button"
              disabled={!selectedTimeSlot}
            >
              Agendar horário
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Calendar;
